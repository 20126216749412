(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/poker-react/components/placeholder/assets/javascripts/placeholder.js') >= 0) return;  svs.modules.push('/modules/poker-react/components/placeholder/assets/javascripts/placeholder.js');
"use strict";


const {
  PokerPaper
} = svs.poker_react.components;
const Placeholder = _ref => {
  let {
    title
  } = _ref;
  return React.createElement(PokerPaper, null, React.createElement("h2", null, title), React.createElement("div", {
    style: {
      height: '800px'
    }
  }));
};
setGlobal('svs.poker_react.components.Placeholder', Placeholder);

 })(window);